import {
  Badge,
  Box,
  Container,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Pagination } from '@material-ui/lab'
import { graphql, PageProps } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import { Button } from 'gatsby-theme-material-ui'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Product from '../components/product'
import theme from '../gatsby-theme-material-ui-top-layout/theme'
import notFalsyPredicate from '../utils/notFalsyPredicate'

type TravelGuidesProps = { data: GatsbyTypes.TravelGuidesQuery } & PageProps

const pageTitle = 'Reiseführer'
const pageDescription =
  'Über 180 Titel zu Reisezielen in aller Welt. Als Print-Ausgabe oder als E-Book.'

const TravelGuides = ({
  data: {
    allMairdumontProduct: { nodes: travelGuides },
    allMairdumontDestination: { nodes: destinations },
    file,
  },
  location,
}: TravelGuidesProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  const metaImageData =
    file &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(file)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { height, width } = metaImageData

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const imagePath = getSrc(file)

  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const itemsPerPage = 8

  const [page, setPage] = React.useState(1)
  const [filterOpen, setFilterOpen] = React.useState(false)
  const [tabValue, setTabValue] = React.useState(0)
  const [filterContinentIds, setFilterContinentIds] = React.useState<number[]>(
    [],
  )
  const [filterCountryIds, setFilterCountryIds] = React.useState<number[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  const continents = destinations.filter((destination) => {
    return destination && destination.destinationType === 'ADMIN_CONTINENT'
  })

  let currentGuides = [...travelGuides]

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageNumber: number,
  ) => {
    setPage(pageNumber)
  }

  const filterByDestinationIds = (dstIds: number[]) => {
    return currentGuides.filter(({ destinations: guideDestinations }) => {
      if (!guideDestinations) return false
      const guidesWithDestination = guideDestinations.find(
        (guidDestination) => {
          if (!guidDestination || !guidDestination.destinationId) return false
          return dstIds.includes(guidDestination.destinationId)
        },
      )
      return !!guidesWithDestination
    })
  }

  const filterBySearch = (search: string) => {
    return currentGuides.filter(
      ({ destinations: searchDestinations, title, isbn }) => {
        if (isbn && (isbn.startsWith(search) || isbn === search)) return true
        if (title && title.toLowerCase().includes(search.toLowerCase())) {
          return true
        }

        return (
          searchDestinations?.find((dst) => {
            return (
              dst &&
              dst.destinationName &&
              dst.destinationName.toLowerCase().includes(search.toLowerCase())
            )
          }) !== undefined
        )
      },
    )
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    setPage(1)
    setSearchValue(value)
  }

  const children = continents
    .filter(({ destinationId }) => {
      return filterContinentIds.includes(destinationId as number)
    })
    .map(({ children: continentChildren, destinationName, destinationId }) => {
      return {
        destinationId,
        destinationName,
        continentChildren,
      }
    })

  const handleCountryClick = (id: number) => {
    setPage(1)
    if (filterCountryIds.includes(id)) {
      setFilterCountryIds(filterCountryIds.filter((i) => i !== id))
    } else {
      setFilterCountryIds([...filterCountryIds, id])
    }
  }

  const handleContinentClick = (id: number) => {
    setPage(1)
    setFilterCountryIds([])
    if (filterContinentIds.includes(id)) {
      setFilterContinentIds([])
    } else {
      setFilterContinentIds([id])
    }
  }

  const handleCountryClickDesktop = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setPage(1)
    if ((event.target.value as number) === 0) {
      setFilterCountryIds([])
    } else {
      setFilterCountryIds([event.target.value as number])
    }
  }

  const handleContinentClickDesktop = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setPage(1)
    setTabValue(newValue)
    setFilterCountryIds([])
    const index = newValue - 1
    if (index === -1) {
      setFilterContinentIds([])
    } else {
      const { destinationId } = continents[index]
      if (destinationId) {
        setFilterContinentIds([destinationId])
      }
    }
  }

  if (filterContinentIds.length) {
    currentGuides = filterByDestinationIds(filterContinentIds)
  }
  if (filterCountryIds.length) {
    currentGuides = filterByDestinationIds(filterCountryIds)
  }
  if (searchValue !== '') {
    currentGuides = filterBySearch(searchValue)
  }
  const pages = Math.ceil(currentGuides.length / itemsPerPage)
  const filterCounter = filterContinentIds.length + filterCountryIds.length

  const ContinentFilter = () => {
    return (
      <>
        {continents
          .map(({ destinationId, destinationName }) => {
            return (
              <Button
                size="small"
                style={{
                  marginRight: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
                {...(filterContinentIds.includes(destinationId as number)
                  ? { variant: 'contained', color: 'secondary' }
                  : { variant: 'outlined' })}
                key={destinationId}
                value={destinationId}
                onClick={() => {
                  handleContinentClick(destinationId as number)
                }}
              >
                {destinationName}
              </Button>
            )
          })
          .filter(notFalsyPredicate)}
      </>
    )
  }

  const DesktopContinentFilter = () => {
    return (
      <Tabs
        scrollButtons="on"
        variant="scrollable"
        value={tabValue}
        onChange={handleContinentClickDesktop}
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Tab label="Alle" />
        {continents
          .map(({ destinationId, destinationName }) => {
            return <Tab label={destinationName} key={destinationId} />
          })
          .filter(notFalsyPredicate)}
      </Tabs>
    )
  }
  const countryData: {
    destinationId: number
    countryName: string
  }[] = children
    .flatMap(({ continentChildren }) => {
      return continentChildren.map(
        (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { destinationId, destinationName: countryName },
        ) => {
          return {
            destinationId: destinationId as number,
            countryName: countryName as string,
          }
        },
      )
    })
    .sort((a, b) => {
      return a.countryName
        .toLowerCase()
        .localeCompare(b.countryName.toLowerCase())
    })

  const CountryFilter = () => {
    return (
      <>
        {children.length > 0 &&
          children
            .map(({ destinationId: continentId }) => {
              return (
                <Grid
                  key={continentId}
                  container
                  spacing={2}
                  style={{ overflowX: 'auto' }}
                  wrap="nowrap"
                >
                  {countryData
                    .map(({ destinationId, countryName }) => {
                      return (
                        <Grid item key={destinationId}>
                          <Button
                            {...(filterCountryIds.includes(destinationId)
                              ? {
                                  variant: 'contained',
                                  color: 'secondary',
                                }
                              : { variant: 'outlined' })}
                            key={destinationId}
                            value={destinationId}
                            size="small"
                            onClick={() => {
                              handleCountryClick(destinationId)
                            }}
                          >
                            {countryName}
                          </Button>
                        </Grid>
                      )
                    })
                    .filter(notFalsyPredicate)}
                </Grid>
              )
            })
            .filter(notFalsyPredicate)}
      </>
    )
  }
  return (
    <Layout
      location={location}
      seoProps={{
        title: pageTitle,
        description: pageDescription,
        images: [
          {
            alt: 'World',
            caption: '',
            path: imagePath ?? '',
            width,
            height,
          },
        ],
      }}
    >
      <Typography component="h1" variant="h2">
        {pageTitle}
      </Typography>
      <Hidden smDown>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <DesktopContinentFilter />
          </Grid>
        </Grid>
      </Hidden>
      <Grid
        container
        alignItems="center"
        direction="row"
        justify="center"
        spacing={1}
      >
        <Hidden smDown>
          <Grid item xs={2}>
            <FormControl style={{ width: '100%', minWidth: 120 }}>
              <InputLabel id="country-filter">Land</InputLabel>
              <Select
                {...(filterContinentIds.length === 0
                  ? { disabled: true }
                  : { disabled: false })}
                value={filterCountryIds[0] ?? 0}
                id="country-filter"
                input={<Input />}
                onChange={handleCountryClickDesktop}
              >
                <ListItem value={0}>Alle</ListItem>
                {countryData
                  .map(({ destinationId, countryName }) => {
                    return (
                      <ListItem key={destinationId} value={destinationId}>
                        {countryName}
                      </ListItem>
                    )
                  })
                  .filter(notFalsyPredicate)}
              </Select>
            </FormControl>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={10}>
          <Box display="flex">
            <TextField
              value={searchValue ?? ''}
              label="Title, Destination, ISBN"
              onChange={onSearchChange}
              fullWidth
              {...(searchValue && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchValue('')
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              })}
            />
            <Hidden mdUp>
              <Button
                aria-label="filter"
                onClick={() => {
                  setFilterOpen(!filterOpen)
                }}
              >
                <Badge badgeContent={filterCounter} color="primary">
                  <FilterListIcon />
                </Badge>
              </Button>
            </Hidden>
          </Box>
        </Grid>
      </Grid>
      {mdUp ? (
        <>
          {filterOpen && (
            <Box>
              <CountryFilter />
            </Box>
          )}
        </>
      ) : (
        <Drawer
          anchor="top"
          open={filterOpen}
          onClose={() => {
            setFilterOpen(false)
          }}
          style={{ height: '100vh' }}
          PaperProps={{ style: { height: '100%' } }}
        >
          <Container
            style={{
              marginTop: theme.spacing(4),
              height: '100%',
            }}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              spacing={4}
              style={{ height: '100%' }}
            >
              <Grid item>
                <Typography variant="h3">Filter</Typography>
              </Grid>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="overline">Kontinente</Typography>
                  <Divider />
                </Grid>
                <Grid item>
                  <ContinentFilter />
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography variant="overline">Länder</Typography>
                  <Divider />
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <CountryFilter />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Button
                  style={{ marginTop: 'auto' }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setFilterOpen(false)
                  }}
                >
                  Filter schließen
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )}

      <Box mt={3}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid container spacing={1} item md={12}>
            {currentGuides
              .map(
                ({
                  id,
                  title,
                  imageUrl,
                  description,
                  productShopLinks,
                  ebook,
                }) => (
                  <Grid item sm={6} md={3} key={id}>
                    <Product
                      product={{
                        id,
                        title,
                        imageUrl,
                        description,
                        productShopLinks,
                        ebook,
                      }}
                      imageHeight={420}
                    />
                  </Grid>
                ),
              )
              .filter(notFalsyPredicate)
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)}
          </Grid>
        </Grid>
        {!currentGuides.length && (
          <Grid container alignItems="center" direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">Leider nichts gefunden.</Typography>
            </Grid>
            {filterCounter > 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setFilterContinentIds([])
                    setFilterCountryIds([])
                    setTabValue(0)
                  }}
                >
                  Filter löschen und erneut suchen
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <Box m={6}>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Pagination
            page={page}
            onChange={handlePageChange}
            count={pages}
            showFirstButton
            showLastButton
          />
        </Grid>
      </Box>
    </Layout>
  )
}

export default TravelGuides

export const travelGuidesQuery = graphql`
  query TravelGuides {
    file(relativePath: { eq: "images/content/home/world.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allMairdumontProduct {
      nodes {
        id
        title
        description
        imageUrl
        isbn
        ebook
        destinations {
          destinationGeographicType
          destinationId
          destinationName
          destinationType
        }
        productShopLinks {
          name
          url
        }
      }
    }
    allMairdumontDestination(sort: { fields: destinationName }) {
      nodes {
        id
        destinationName
        destinationId
        children {
          id
          ... on MairdumontDestination {
            id
            destinationName
            destinationType
            destinationId
          }
        }
        destinationType
      }
    }
  }
`
